<template>
    <!-- 分销订单 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">订单中心</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">分销订单</a>
                </span>
            </div>
            <div class="framePage-body">
                <el-tabs>
                    <el-tab-pane label="个人分销">
                        <!-- 搜索区域 -->
                        <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
                            <!-- 搜索区域 -->
                            <div class="searchbox">
                                <div title="支付时间" class="searchboxItem">
                                    <span class="itemLabel">支付时间:</span>
                                    <el-date-picker
                                        @change="datePickerChange"
                                        v-model="searchForm.paymentTime"
                                        type="daterange"
                                        size="small"
                                        value-format="yyyy-MM-dd"
                                        clearable
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间">
                                    </el-date-picker>
                                </div>
                                <div title="分销人" class="searchboxItem">
                                    <span class="itemLabel">分销人:</span>
                                    <el-input class="giao" v-model="searchForm.mobile" @input="filter('mobile')" placeholder="请输入分销人手机号" type="text" clearable size="small"/>
                                </div>
                                <div title="商品名称" class="searchboxItem">
                                    <span class="itemLabel">商品名称:</span>
                                    <el-input v-model="searchForm.goodsName" placeholder="请输入商品名称" type="text" clearable size="small"/>
                                </div>
                                <div class="searchboxItem">
                                    <el-button type="primary" class="bgc-bv" round @click="searchData(1)">查询</el-button>
                                    <el-button type="primary" class="bgc-bv" round @click="exportData">导出</el-button>
                                </div>
                            </div>
                            <div class="searchbox" style="margin-top:20px;">
                                <div class="searchboxItem">
                                    <span class="itemLabel">实付金额汇总：￥{{payMoneyTotal}}</span>
                                </div>
                                <div class="searchboxItem">
                                    <span class="itemLabel">个人分成汇总：￥{{profitMoneyTotal}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 列表区域 -->
                        <div class="framePage-scroll">
                            <div class="ovy-a">
                                <el-table
                                    ref="multipleTable"
                                    :data="dataList"
                                    :height="tableHeight"
                                    size="small"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                    :header-cell-style="tableHeader"
                                    stripe
                                >
                                    <el-table-column
                                        label="序号"
                                        align="center"
                                        type="index"
                                        :index="indexMethod"
                                        width="50"
                                    />
                                    <el-table-column
                                        label="订单编号"
                                        align="left"
                                        prop="orderSn"
                                        min-width="130"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column
                                        label="商品名称"
                                        align="left"
                                        prop="goodsName"
                                        min-width="130"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column
                                        label="商品分类"
                                        align="left"
                                        prop="goodsType"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column
                                        label="商品价格"
                                        align="left"
                                        show-overflow-tooltip
                                    >
                                        <template v-slot="{row}">
                                            ￥{{row.goodsMoneyStr}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="实付金额"
                                        align="left"
                                        show-overflow-tooltip
                                    >
                                        <template v-slot="{row}">
                                            ￥{{row.payMoneyStr}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="分润比例"
                                        align="left"
                                        show-overflow-tooltip
                                        prop="shareProfitStr">
                                    </el-table-column>
                                    <el-table-column
                                        label="个人成分"
                                        align="left"
                                        show-overflow-tooltip
                                    >
                                        <template v-slot="{row}">
                                            ￥{{row.profitMoneyStr}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="分销人"
                                        align="left"
                                        prop="beneficiaryMobile"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column
                                        label="购买人"
                                        align="left"
                                        prop="buyerMobile"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column
                                        label="支付方式"
                                        align="left"
                                        prop="paymentMethodStr"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column
                                        label="支付时间"
                                        align="left"
                                        prop="payDateTimeStr"
                                        show-overflow-tooltip
                                    />
                                    <Empty slot="empty" />
                                </el-table>
                            </div>
                        </div>
                        <!-- 分页器 -->
                        <PageNum :apiData="pagination" @sizeChange="sizeChange" @getData="currentChange"/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import Empty from "@/components/Empty.vue";
    import PageNum from "@/components/PageNum.vue";
    import List from "@/mixins/List";
    import { mapGetters } from "vuex";
    export default {
        name:"distributionOrder",
        components:{
            Empty,
            PageNum
        },
        mixins:[List],
        created(){
            this.initData();
        },
        data(){
            return{
                // 列表数据
                dataList:[],
                // 实付金额汇总
                payMoneyTotal:0,
                // 个人分成汇总
                profitMoneyTotal:0,
                // 查询表单
                searchForm:{
                    paymentTime:[], //支付时间
                    mobile:"", //分销人手机号
                    goodsName:"", // 商品名称
                    payDateFront:"", // 支付时间前
                    payDateBack:"", // 支付时间后
                },
                // 分页器数据
                pagination:{
                    current:1,
                    size:10,
                    total:0
                }
            }
        },
        computed:{
            ...mapGetters({
                downloadItems: "getDownloadItems",
            })
        },
        methods:{
          // 序号
          indexMethod(index) {
            return (this.pagination.current - 1) * this.pagination.size + index + 1;
          },
            // 初始化数据
            initData(){
                this.getDataList();
                this.getSummaryData();
            },
            searchData(query){
                this.getDataList(query);
                this.getSummaryData();
            },
            // 获取数据列表
            getDataList(query){
                const {searchForm,pagination}=this;
                if(typeof query==="number") pagination.current=query;
                const data={
                    ...searchForm,
                    pageNum:pagination.current,
                    pageSize:pagination.size
                }
                delete data.paymentTime;
                // 发起请求
                this.$post("/userDistribution/profit/orderDistributionList",data,3000,true,2).then(res=>{
                    this.dataList=res?.data?.list||[];
                    pagination.total=res?.data?.total||0;
                }).catch(e=>{
                    console.error("获取列表数据出错",e);
                });
            },
            // 获取汇总数据
            getSummaryData(){
                const {searchForm}=this;
                const data={...searchForm}
                delete data.paymentTime;
                this.$post("/userDistribution/profit/orderDistributionListSummary",data,3000,true,2).then(res=>{
                    this.payMoneyTotal=res?.data?.payMoneyTotal||0;
                    this.profitMoneyTotal=res?.data?.profitMoneyTotal||0;
                }).catch(e=>{
                    console.error("获取汇总信息出错",e);
                });
            },
            // 导出
            exportData(){
                const {searchForm}=this;
                // 请求参数
                const data={
                    ...searchForm,
                }
                delete data.paymentTime;
                this.$post("/userDistribution/profit/orderDistributionListExport",data,3000,true,2).then(res=>{
                    if (!this.downloadItems.includes(res.data.taskId)) {
                        this.$store.dispatch("pushDownloadItems", res.data.taskId);
                    } else {
                        this.$message.warning("[" + res.data.fileName + "]已经申请下载,请耐心等待");
                    }
                }).catch(e=>{
                    console.error("导出列表出错",e);
                });
            },
            // 支付时间改变
            datePickerChange(value){
                const {searchForm}=this;
                if(value){
                    searchForm.payDateFront=value[0];
                    searchForm.payDateBack=value[1];
                }else{
                    searchForm.payDateFront="";
                    searchForm.payDateBack="";
                }
            },
            // 分页器当前页改变
            currentChange(val){
                const {pagination}=this;
                pagination.current=val;
                this.searchData();
            },
            // 分页器条数改变
            sizeChange(val){
                const {pagination}=this;
                pagination.size=val;
                this.searchData();
            },
            // 过滤非法数据
            filter(key){
                const {searchForm}=this;
                const value=searchForm[key].replace(/\D/ig,"");
                searchForm[key]=value;
            },
            // 设置表格高度
            getTableHeight(opDom = false, page = true) {
                let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
                if (opDom)  tHeight -= 40 + 0.675 * 16 + 1;
                if (page)  tHeight -= 100;
                this.tableHeight = tHeight;
            }
        }
    }   
</script>

<style lang="less" scoped>
    .searchboxItem{
        display: flex;
        .giao{
            flex-grow: 1;
        }
    }
</style>